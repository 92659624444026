<script>
	import { page } from '$app/stores';
  import NewsletterSection from './NewsletterSection.svelte';
	import WishSection from './WishSection.svelte';

  export let additionalClass = ""

</script>

{#if $page.data.session}
  <WishSection {additionalClass} />
{:else}
  <NewsletterSection {additionalClass} />
{/if}
