import type { Database } from '../../types/supabase';
import { AbstractSupabaseApi } from './AbstractSupabaseApi';

/**
 * As an API this just wraps the provided functions from supabase. It does not decide for or add any business logic.
 * BL will be implemented in the related *.service.ts file.
 */

export type Suggestion = Database['public']['Tables']['suggestion'];

class SuggestionApi extends AbstractSupabaseApi<Suggestion> {}
export const suggestionApi = new SuggestionApi('suggestion');
