<script lang="ts">
	import { classes } from '$lib/utils';
	import { getTranslate } from '@tolgee/svelte';
	import Button from '../Button/Button.svelte';
	import Rectangle from '$lib/assets/gardientRectangle.png';
	import ArrowIcon from '../Icon/ArrowIcon.svelte';
	import LineIcon from '../Icon/LineIcon.svelte';
	import { suggestionService } from '../../service/supabase/suggestion.service';
	import { page } from '$app/stores';
	import { notifications, NotificationType } from '../../store/notification.store';
	import { screenSize } from '$lib/store/breakpoints.store';

	export let additionalClass = '';
	export let tolgeePrefix = '__global';
	const { t } = getTranslate();

	$: isMobile = $screenSize === 'mobile';

	let idea = '';
	let isLoading = false;
	let hasError = false;
	let hasFocus = false;

	async function onSubmit(): Promise<void> {
		if (!idea) {
			return;
		}
		isLoading = true;
		hasError = false;
		try {
			await suggestionService.create({
				content: idea,
				user_id: $page.data.session?.user?.id
			});
			hasError = false;
			idea = '';
			notifications.createTolgeeNotification({
				message: `${tolgeePrefix}.wish-section.success`,
				type: NotificationType.Success
			});
		} catch (e) {
			hasError = true;
		} finally {
			isLoading = false;
		}
	}
</script>

<section class={classes('flex flex-col md:flex-row w-full py-0 mt-16', additionalClass)}>
	<div class="w-full">
		<img src={Rectangle} alt="gardient" class="w-[90%] h-[89px] mb-px" />
		<div class=" bg-black flex flex-row justify-between">
			<div class="text-white pl-6 md:mr-7">
				<h2 class="font-bold text-[40px] pt-16 leading-10">
					{@html $t(`${tolgeePrefix}.wish-section.title`)}
				</h2>
				<p id={`${isMobile ? 'wish-paragraph' : ''}`} class="text-2xl font-normal mt-8">
					{@html $t(`${tolgeePrefix}.wish-section.text`)}
				</p>
				<div class="flex flex-col mt-12 gap-7">
					<textarea
						on:focus={() => (hasFocus = true)}
						on:blur={() => setTimeout(() => (hasFocus = false), 150)}
						bind:value={idea}
						style="resize: none;"
						class="text-black px-4 py-3 bg-gray-50 rounded-lg max-w-xl focus:outline-none {idea
							? 'h-32'
							: 'h-12'} h-12 max-h-fit focus:h-32 focus:ring-0 focus:border-purple transition-all"
						placeholder={$t(`${tolgeePrefix}.wish-section.input.placeholder`)}
					/>
					<Button
						on:click={onSubmit}
						disabled={idea.trim().length === 0 || isLoading}
						additionalClass="font-bold self-start !px-4"
						>{@html isLoading
							? $t(`${tolgeePrefix}.wish-section.btn-loading`)
							: hasError
							? $t(`${tolgeePrefix}.wish-section.btn-error`)
							: $t(`${tolgeePrefix}.wish-section.btn`)}</Button
					>
				</div>
			</div>
			<div class="flex flex-row mr-[10%]">
				<ArrowIcon additionalClass="w-[230px] mt-20 hidden lg:block" />
				<LineIcon additionalClass="mt-[-89px] h-40 hidden lg:block lg:h-[480px]" />
			</div>
		</div>
	</div>
</section>
